.containerDeclarationsPages {
    direction: rtl;
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.containerDeclarationsPages h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
    text-align: center;
    color: #2c3e50;
}

.containerDeclarationsPages h2 {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 0.5em;
    color: #2980b9;
}

.containerDeclarationsPages p {
    margin-bottom: 1em;
}

.containerDeclarationsPages ul {
    margin-bottom: 1em;
    padding-left: 20px;
}

.containerDeclarationsPages ul li {
    margin-bottom: 0.5em;
}

@media (max-width: 600px) {
    .containerDeclarationsPages {
        padding: 10px;
    }

    .containerDeclarationsPages h1 {
        font-size: 1.5em;
    }

    .containerDeclarationsPages h2 {
        font-size: 1.2em;
    }
}
