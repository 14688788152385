.search-container {
    direction: rtl;
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    z-index: 15;
}

.search-input {
    direction: rtl;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    background-color: #fff;
    color: #333;
}
.search-input::-webkit-search-cancel-button {
    cursor: pointer;
    font-size: 22px;
}
.search-input:focus {
    outline: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.search-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: 20.8rem;
    width: 100%;
    max-width: 600px;
}

.li-results {
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    font-weight: 500;
}

.li-results:hover {
    background-color: #dcc8a3;
}

@media (max-width: 768px) {
    .search-container ul {
        max-width: 90%;
    }
}

@media (max-width: 480px) {
    .search-container ul {
        max-width: 80%;
    }
}