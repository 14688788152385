/*!* GardenFurniture.css *!*/

/*.cards-container {*/
/*    padding: 20px;*/
/*    text-align: center;*/
/*}*/

/*.cards-grid {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    gap: 20px;*/
/*    justify-content: center;*/
/*}*/

/*.card {*/
/*    background-color: #fff;*/
/*    border: 1px solid #ddd;*/
/*    border-radius: 5px;*/
/*    overflow: hidden;*/
/*    text-align: center;*/
/*    transition: transform 0.3s;*/
/*}*/

/*.card:hover {*/
/*    transform: scale(1.05);*/
/*}*/

/*.card-link {*/
/*    color: inherit;*/
/*    text-decoration: none;*/
/*}*/

/*.card-image {*/
/*    width: 100%;*/
/*    height: auto;*/
/*}*/

/*.card-content {*/
/*    padding: 10px;*/
/*}*/

/*.slick-prev, .slick-next {*/
/*    width: 30px;*/
/*    height: 30px;*/
/*    background-color: brown;*/
/*    z-index: 1;*/
/*}*/

/*.slick-prev:before, .slick-next:before {*/
/*    font-size: 30px;*/
/*    color: white;*/
/*}*/
