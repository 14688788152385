.whatsapp-floating-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    z-index: 9999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out; /* הוספת מעבר לתנועה */
}

.whatsapp-floating-button:hover {
    transform: translateY(-5px); /* הזזת הכפתור כלפי מעלה בהנחת העכבר */
}