.main-container{
    background-image: url("../../images/wood-working-2385634_1920.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem;
    /*color: #fff;*/
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}
.main-container::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}
.logo-container {
    z-index: 2;
    margin-bottom: 1.5rem;
}

.navbar-logo img {
    height: 13rem;
    width: auto;
}

.quick-choice-title {
    color: #cfa54b;
    font-size: 2.4rem;
    z-index: 2;
    font-family: "Amatic SC", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.project-cards-container {
    cursor: pointer;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    z-index: 2;
}

.project-card {
    display: flex;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 250px;
    flex: 1 1 200px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    color: inherit;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.hero-project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.project-title {
    text-align: center;
    padding: 1rem;
    font-size: 1.5rem;
    font-family: "Amatic SC", sans-serif;
    font-weight: bold;
    color: #333;
}

.section {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.section-pergolah {
    background: url('../../images/bench.png') no-repeat center center;
    background-size: cover;
}

.section-swing {
    background: url('../../images/fence.png') no-repeat center center;
    background-size: cover;
}

.section-table {
    background: url('../../images/logo2.jpg') no-repeat center center;
    background-size: cover;
}
/* Responsive styles */
@media (max-width: 768px) {
    .project-cards-container {
        gap: 1rem;
    }

    .project-card {
        flex: 1 1 calc(50% - 1rem); /* Two cards per row */
    }
}

@media (max-width: 480px) {
    .project-card {
        flex: 1 1 100%; /* One card per row */
    }
}
