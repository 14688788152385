.bg1-container{
    /*background-image: url('../../images/wooden-swing.jpg');*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*background-position: center;*/
    /*background-attachment: fixed;*/
    /*height: 60vh;*/
    /*margin: 0;*/
}
.bg2-container{
    background-color: #f5f0ec;
    background-image: url('../../images/logo1-removebg-preview.png');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: auto;
    height: 60vh;
    margin: 0;
}