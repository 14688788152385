.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: #333;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.scroll-to-top.visible {
    opacity: 0.8;
}

.scroll-to-top:hover {
    opacity: 1;
}

.scroll-to-top i {
    font-size: 20px;
}